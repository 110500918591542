<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('signals') }}</h3>

    <div class="rounded-lg mb-5" :class="[$theme.cardBg]">
      <div class="px-3">
        <SwitchGroup
            as="div"
            :class="['flex items-center justify-between py-2']"
        >
          <span class="flex flex-grow flex-col">
            <SwitchLabel
                as="span"
                class="flex items-center justify-between text-sm font-medium leading-6"
                :class="[$theme.baseTextColor]"
                passive
            >
              <div>
                <div class="flex items-center">
                  <div class="mr-1 flex items-center">
                    <span class="ml-1">{{ $t('stop_all_signals') }}</span>
                  </div>
                </div>
              </div>
            </SwitchLabel>
          </span>
          <Switch
              @click.prevent="toggleDisableAllSignals"
              :class="[
              isAllSignalsDisabled ? $theme.switchBgActive : $theme.switchBgInactive,
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
              $theme.switchRingFocus
            ]"
          >
            <span
                aria-hidden="true"
                :class="[
                isAllSignalsDisabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              ]"
            />
          </Switch>
        </SwitchGroup>
      </div>
    </div>

    <!-- Условное отображение блока с вкладками -->
    <div class="block" v-if="hasUSDCSignals">
      <nav class="flex space-x-4 mb-5" aria-label="Tabs">
        <a
            v-for="tab in filtersByTypeAsset"
            :key="tab.key"
            href="javascript:void(0)"
            @click="onChangeTypeAsset(tab.key)"
            :class="[
            tab.key === filters.activeTypeAsset ? `${$theme.activeTabBg} ${$theme.activeTabTextColor}` : $theme.tabTextColor,
            'rounded-md px-3 py-2 text-sm font-medium'
          ]"
        >{{ tab.name }}</a>
      </nav>
    </div>

    <div v-if="loading" class="rounded-xl overflow-hidden mt-4" :class="[$theme.cardBg]">
      <LoadingBlock />
    </div>
    <template v-else>
      <template v-if="signals.length">
        <div class="rounded-xl" :class="[$theme.cardBg]">
          <div
              v-for="(signal, signalIndex) in signals"
              :key="signal.id"
              class="px-3 relative"
          >
            <SwitchGroup
                as="div"
                :class="[
                'flex items-center justify-between py-2',
                signalIndex === signals.length - 1 ? '' : 'border-b',
                $theme.cardBorderColor
              ]"
            >
              <span class="flex flex-grow flex-col">
                <SwitchLabel
                    as="span"
                    class="flex items-center justify-between text-sm font-medium leading-6"
                    :class="[$theme.baseTextColor]"
                    passive
                >
                  <div
                      :class="[
                      allowedSignalsIds.indexOf(signal.id) === -1 ? 'opacity-60' : ''
                    ]"
                  >
                    <div class="flex items-center">
                      <router-link
                          :to="`/signals/${signal.id}`"
                          class="mr-1 flex items-center"
                      >
                        <QuestionMarkCircleIcon class="w-4 h-4" :class="[$theme.cardLabelTextColor]" />
                        <span class="ml-1">{{ signal.name }}</span>
                      </router-link>
                      <template v-if="signal.level_risk === 'low'">
                        <TheTooltip
                            :id="`${signal.id}_${signal.max_leverage}`"
                            :message="$t('max_leverage')"
                        >
                          <span class="text-xs text-green-custom">
                            x{{ signal.max_leverage }}
                          </span>
                        </TheTooltip>
                      </template>
                      <template v-if="signal.level_risk === 'medium'">
                        <TheTooltip
                            :id="`${signal.id}_${signal.max_leverage}`"
                            :message="$t('max_leverage')"
                        >
                          <span class="text-xs text-yellow-500">
                            x{{ signal.max_leverage }}
                          </span>
                        </TheTooltip>
                      </template>
                      <template v-if="signal.level_risk === 'high'">
                        <TheTooltip
                            :id="`${signal.id}_${signal.max_leverage}`"
                            :message="$t('max_leverage')"
                        >
                          <span class="text-xs text-red-600">
                            x{{ signal.max_leverage }}
                          </span>
                        </TheTooltip>
                      </template>
                    </div>

                    <div class="leading-none">
                      <template v-if="signal.level_risk === 'low'">
                        <TheTooltip
                            :id="`${signal.id}_${signal.max_leverage}_lowrisk`"
                            message="Торговая пара требует обеспечение на счету 1 к 6 относительно суммы в торгах."
                        >
                          <span
                              class="text-xs text-green-custom flex items-center"
                          >
                            <BoltIcon class="w-3 mr-1" /> {{ $t('low_risk') }}
                          </span>
                        </TheTooltip>
                      </template>
                      <template v-if="signal.level_risk === 'medium'">
                        <TheTooltip
                            :id="`${signal.id}_${signal.max_leverage}_mediumrisk`"
                            message="Торговая пара требует обеспечение на счету 1 к 8 относительно суммы в торгах."
                        >
                          <span
                              class="text-xs text-yellow-500 flex items-center"
                          >
                            <BoltIcon class="w-3 mr-1" />
                            {{ $t('medium_risk') }}
                          </span>
                        </TheTooltip>
                      </template>
                      <template v-if="signal.level_risk === 'high'">
                        <TheTooltip
                            :id="`${signal.id}_${signal.max_leverage}_highrisk`"
                            message="Торговая пара требует обеспечение на счету 1 к 12 относительно суммы в торгах."
                        >
                          <span
                              class="text-xs text-red-600 flex items-center"
                          >
                            <BoltIcon class="w-3 mr-1" />
                            {{ $t('high_risk') }}
                          </span>
                        </TheTooltip>
                      </template>
                    </div>
                  </div>
                  <div
                      class="flex items-center mr-3"
                      :class="[
                      allowedSignalsIds.indexOf(signal.id) === -1 ? 'opacity-60' : ''
                    ]"
                  >
                    <template
                        v-for="(allow_exchange, aeIdx) in signal.exchanges"
                        :key="aeIdx"
                    >
                      <TheTooltip
                          :id="`${allow_exchange}_${signal.id}`"
                          :message="capitalizeFirstLetter(allow_exchange)"
                      >
                        <img
                            :src="`/img/${allow_exchange}.png`"
                            alt=""
                            class="w-5 rounded-full ml-1"
                        />
                      </TheTooltip>
                    </template>
                  </div>
                </SwitchLabel>
              </span>
              <template v-if="allowedSignalsIds.indexOf(signal.id) === -1">
                <TheTooltip
                    :id="`${signal.id}_${signal.max_leverage}_lock`"
                    :message="`${$t('trading_pair_available_in_tariffs')} ${renderTariffNames(signal.tariffs)}`"
                >
                  <LockClosedIcon class="w-5 opacity-30" />
                </TheTooltip>
              </template>
              <template v-else>
                <Switch
                    @click="toggleSignal(signal.id)"
                    :class="[
                    signalEnabled.indexOf(signal.id) !== -1
                      ? $theme.switchBgActive
                      : $theme.switchBgInactive,
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
                    $theme.switchRingFocus
                  ]"
                >
                  <span
                      aria-hidden="true"
                      :class="[
                      signalEnabled.indexOf(signal.id) !== -1
                        ? 'translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    ]"
                  />
                </Switch>

                <div class="ml-2">
                  <router-link :to="`/signals/${signal.id}/settings`" class="opacity-50">
                    <Cog6ToothIcon class="w-5" />
                  </router-link>
                </div>
              </template>
            </SwitchGroup>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="mt-5 rounded-xl overflow-hidden px-4 py-4" :class="[$theme.cardBg]">
          <p class="text-center">{{ $t('no_signals_for_selected_asset') }}</p>
        </div>
      </template>
    </template>

    <TransitionRoot as="template" :show="openConfirmDisableTrade">
      <Dialog class="relative z-50" @close="cancelToggleAllSignals">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div class="fixed inset-0 z-50 flex items-end justify-center">
          <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-300"
              enter-from="translate-y-full opacity-0"
              enter-to="translate-y-0 opacity-100"
              leave="transform transition ease-in-out duration-300"
              leave-from="translate-y-0 opacity-100"
              leave-to="translate-y-full opacity-0"
          >
            <DialogPanel class="w-full bg-white rounded-t-2xl px-4 pt-5 pb-4 text-left shadow-xl sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{
                      confirmDisableAll
                          ? $t('confirm_disable_all_signals')
                          : $t('confirm_enable_all_signals')
                    }}
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      {{
                        confirmDisableAll
                            ? $t('are_you_sure_you_want_to_disable_all_signals')
                            : $t('are_you_sure_you_want_to_enable_all_signals')
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button
                    @click="confirmToggleAllSignals"
                    type="button"
                    class="w-full inline-flex justify-center rounded-xl border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white"
                    :class="[$theme.inverseBaseBg]"
                >
                  {{ $t('confirm') }}
                </button>
                <button
                    @click="cancelToggleAllSignals"
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  {{ $t('cancel') }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import TheTooltip from "@/components/elem/TheTooltip.vue";
import {
  Switch,
  SwitchGroup,
  SwitchLabel,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  BoltIcon,
  QuestionMarkCircleIcon,
  LockClosedIcon,
  Cog6ToothIcon,
} from "@heroicons/vue/16/solid";
import { addBackButton, hideBackButton } from "@/helpers/telegramHelper";
import { capitalizeFirstLetter } from "@/helpers/stringHelper";
import LoadingBlock from "@/components/LoadingBlock.vue";

export default {
  components: {
    LoadingBlock,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TheTooltip,
    BoltIcon,
    QuestionMarkCircleIcon,
    LockClosedIcon,
    Cog6ToothIcon,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },

  data() {
    return {
      loading: true,
      signalEnabled: [],
      signals: [],
      signalsByTypeAsset: {
        USDT: [],
        USDC: [],
      },
      error: null,
      openConfirmDisableTrade: false,
      confirmDisableAll: false,
      capitalizeFirstLetter,
      user: null,

      filtersByTypeAsset: [
        {
          name: 'USDT',
          key: 'USDT',
        },
        {
          name: 'USDC',
          key: 'USDC',
        },
      ],

      filters: {
        activeTypeAsset: 'USDT'
      },

      hasUSDCSignals: false, // Новое свойство для хранения наличия USDC сигналов
    };
  },

  computed: {
    tariffSignals() {
      return this.user?.tariff?.signals ?? [];
    },

    isMexc() {
      return (
          this.user?.tariff?.tariff?.exchanges &&
          this.user.tariff.tariff.exchanges.indexOf('mexc') !== -1
      );
    },

    allowedSignalsIds() {
      if (this.user?.tariff?.signals && this.user.tariff.signals.length > 0) {
        return this.user.tariff.signals.map((signal) => signal.id);
      } else {
        return this.signals.map((signal) => signal.id);
      }
    },

    isAllSignalsDisabled() {
      return this.allowedSignalsIds.every(
          (id) => !this.signalEnabled.includes(id)
      );
    },
  },

  methods: {
    renderTariffNames(tariffs) {
      return tariffs.map((tariff) => " - " + tariff.title).join("<br>");
    },

    toggleDisableAllSignals(event) {
      event.preventDefault();
      if (!this.isAllSignalsDisabled) {
        // Пользователь хочет отключить все сигналы
        this.confirmDisableAll = true;
      } else {
        // Пользователь хочет включить все сигналы
        this.confirmDisableAll = false;
      }
      this.openConfirmDisableTrade = true;
    },

    confirmToggleAllSignals() {
      if (this.confirmDisableAll) {
        // Отключаем все сигналы
        this.signalEnabled = [];
      } else {
        // Включаем все разрешённые сигналы
        this.signalEnabled = [...this.allowedSignalsIds];
      }
      this.updateUserSettings();
      this.openConfirmDisableTrade = false;
    },

    cancelToggleAllSignals() {
      this.openConfirmDisableTrade = false;
    },

    toggleSignal(id) {
      const index = this.signalEnabled.indexOf(id);
      if (index === -1) {
        this.signalEnabled.push(id);
      } else {
        this.signalEnabled.splice(index, 1);
      }
      this.updateUserSettings();
    },

    updateUserSettings() {
      this.$api
          .post("/user/update-settings", {
            signals: this.signalEnabled,
          })
          .then((result) => {
            console.log("Settings updated.", result.data.signals);
            this.$store.commit('updateEnabledSignals', result.data.signals);
          })
          .catch((e) => {
            console.error(e);
            this.error = e;
          });
    },

    onChangeTypeAsset(type) {
      this.filters.activeTypeAsset = type;
      this.signals = this.signalsByTypeAsset[type] || [];
    },

    loadSignals() {
      this.loading = true;

      this.$api.get('/signals') // Загружаем все сигналы без фильтрации
          .then((result) => {
            const allSignals = result.data;

            // Разделяем сигналы по type_asset на основе 'ticker' поля
            this.signalsByTypeAsset = {
              USDT: allSignals.filter(signal => signal.ticker.includes('USDT')),
              USDC: allSignals.filter(signal => signal.ticker.includes('USDC')),
            };

            // Устанавливаем активные сигналы на основе текущего фильтра
            this.signals = this.signalsByTypeAsset[this.filters.activeTypeAsset] || [];

            // Определяем, есть ли сигналы для USDC
            this.hasUSDCSignals = this.signalsByTypeAsset.USDC.length > 0;

            // Сброс флага, если активный тип актива изменился и это не USDC
            if (this.filters.activeTypeAsset !== 'USDC' && this.signalsByTypeAsset.USDC.length === 0) {
              this.hasUSDCSignals = false;
            }

            this.loading = false;

            // Отладка: выводим полученные данные
            console.log("All Signals:", allSignals);
            console.log("Signals By Type Asset:", this.signalsByTypeAsset);
            console.log("Active Signals:", this.signals);
            console.log("Has USDC Signals:", this.hasUSDCSignals);
          })
          .catch((e) => {
            console.error(e);
            this.error = e;
            this.loading = false;
          });
    },

    loadUser() {
      this.$api
          .get("/user")
          .then((result) => {
            this.user = result.data.data;
            this.signalEnabled = this.user.settings.signals;
          })
          .catch((e) => {
            console.error(e);
            this.error = e;
          });
    },
  },

  mounted() {
    this.loadUser();
    this.loadSignals();
    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  },
};
</script>

<style scoped>
/* Ваши стили здесь */
</style>
